import {FastField, Field, getIn} from 'formik';
import React, {useRef} from 'react';

import Autocomplete from 'apollo-react/components/Autocomplete';
import Checkbox from 'apollo-react/components/Checkbox';
import DatePicker from 'apollo-react/components/DatePicker';
import FileInput from 'apollo-react/components/FileInput';
import FormGroup from 'apollo-react/components/FormGroup';
import FormHelperText from 'apollo-react/components/FormHelperText';
import PasswordComplexity from 'apollo-react/components/PasswordComplexity';
import PasswordInput from 'apollo-react/components/PasswordInput';
import Select from 'apollo-react/components/Select';
import TextField from 'apollo-react/components/TextField';

import CustomAutoComplete from '../AutoComplete';
import {checkDuplicateValues} from '../../../features/projects/slices/CreateProjectSlice';
import {useDispatch} from 'react-redux';
import {CONSTANTS} from '../../../common/constants';
import {RadioGroup} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {debounce} from 'lodash';

export const disbledTextFieldStyles = makeStyles((theme) => ({
  opacityOne: {
    opacity: '1 !important',
    color: `${theme.palette.common.black} !important`,
  },
}));

export const styles = {
  topSpacer: {
    marginTop: 16,
  },
  phoneNumber: {
    margin: '16px 0',
  },
  passwordWrapper: {
    display: 'flex',
    alignItems: 'start',
  },
  passwordComplexity: {
    marginTop: 54,
  },
  thumb: {
    width: 200,
    height: 200,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
};

export const FormikTextField = ({name, helperText = '', ...rest}) => {
  const classes = disbledTextFieldStyles();

  const handleBlurRef = useRef(
    debounce((value) => {
      rest.handleOnBlur && rest.handleOnBlur(value);
    }, 300),
  );

  const handleChangeRef = useRef(
    debounce((e) => {
      rest.handleChange && rest.handleChange(e);
    }, 300),
  );

  return (
    <Field name={name} validate={rest.validate}>
      {({field, form: {errors, touched}}) => {
        const fieldError = getIn(errors, name);
        const getHelperText = () => {
          return (
            rest.displayError !== false && (
              <span dangerouslySetInnerHTML={{__html: fieldError || helperText}}></span>
            )
          );
        };

        const handleBlur = (e) => {
          const {value} = e.target;
          rest.handleOnBlur && handleBlurRef.current(value);
        };

        const handleChange = (e) => {
          field.onChange(e);
          rest.handleChange && handleChangeRef.current(e);
        };

        return (
          <TextField
            helperText={getHelperText()}
            error={!!fieldError}
            {...field}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={(e) => {
              rest.handleOnFocus && rest.handleOnFocus(e);
            }}
            {...rest}
            InputProps={{
              classes: {
                disabled: classes.opacityOne,
              },
              onWheel: (e) => {
                rest.type === 'number' && e.target.blur();
              },
            }}
            InputLabelProps={{
              style: {opacity: 1},
            }}
            FormHelperTextProps={{
              className: rest.disabled && !fieldError ? classes.opacityOne : '',
            }}
          />
        );
      }}
    </Field>
  );
};

export const FormikTextFieldCustomValidate = ({name, helperText, validate, ...rest}) => (
  <Field name={name} validate={validate}>
    {({field, form: {errors, touched}}) => (
      <TextField
        helperText={(touched[name] && errors[name]) || helperText}
        error={!!(touched[name] && errors[name])}
        {...field}
        onBlur={(e) => {
          field.onBlur(e);
          rest.handleOnBlur && rest.handleOnBlur(e.target.value);
        }}
        onChange={(e) => {
          field.onChange(e);
          rest.handleChange && rest.handleChange(e);
        }}
        onFocus={(e) => {
          rest.handleOnFocus && rest.handleOnFocus(e);
        }}
        {...rest}
      />
    )}
  </Field>
);

export const FormikRadioGroupBase = ({name, helperText, classes, ...rest}) => (
  <Field name={name}>
    {({field, form: {errors, touched}}) => (
      <RadioGroup
        aria-label={name}
        {...field}
        onBlur={(e) => {
          e.target.name = name;
          field.onBlur(e);
        }}
        onChange={(e) => {
          field.onChange(e);
          rest.handleChange && rest.handleChange(e);
        }}
        className={classes.topSpacer}
        error={touched[name] && !!errors[name]}
        helperText={(touched[name] && errors[name]) || helperText}
        {...rest}
      />
    )}
  </Field>
);

export const FormikRadioGroup = withStyles(styles)(FormikRadioGroupBase);

export const FormikCheckbox = ({name, ...rest}) => (
  <Field name={name}>
    {({field: {value, ...field}, form: {errors, touched, setFieldValue}}) => (
      <FormGroup>
        <Checkbox
          checked={value}
          value={name}
          {...field}
          onChange={(e) => {
            setFieldValue(name, e.target.checked);
            rest.handleChange && rest.handleChange(e.target.checked);
          }}
          {...rest}
        />
        {touched[name] && errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
      </FormGroup>
    )}
  </Field>
);

export const FormikSelect = ({name, helperText, ...rest}) => (
  <Field name={name}>
    {({field: {value, onChange}, form: {errors, touched, setFieldTouched}}) => {
      const classes = disbledTextFieldStyles();
      const fieldError = getIn(errors, name);
      return (
        <Select
          canDeselect={false}
          helperText={(touched[name] && errors[name]) || helperText}
          error={!!(touched[name] && errors[name])}
          onChange={(e) => {
            setFieldTouched(name, true);
            onChange({target: {name, value: e.target.value}});
            rest.handleChange && rest.handleChange(e.target.value);
          }}
          value={value || []}
          InputProps={{
            classes: {
              disabled: classes.opacityOne,
            },
          }}
          InputLabelProps={{
            style: {opacity: 1},
          }}
          FormHelperTextProps={{
            className: rest.disabled && !fieldError ? classes.opacityOne : '',
          }}
          {...rest}
        />
      );
    }}
  </Field>
);

export const FormikSelectCustom = ({name, helperText, ...rest}) => (
  <Field name={name}>
    {({field, form: {errors, touched}}) => (
      <Select
        canDeselect={false}
        helperText={(touched[name] && errors[name]) || helperText}
        error={!!(touched[name] && errors[name])}
        {...field}
        {...rest}
      />
    )}
  </Field>
);

export const FormikAutocomplete = ({name, helperText, ...rest}) => (
  <Field name={name}>
    {({field: {onChange}, form: {errors, touched, setFieldTouched}}) => (
      <Autocomplete
        helperText={(touched[name] && errors[name]) || helperText}
        onChange={(event, value) => {
          setFieldTouched(name, true);
          onChange({target: {value, name}});
          rest.handleChange && rest.handleChange(value);
        }}
        error={!!(touched[name] && errors[name])}
        {...rest}
      />
    )}
  </Field>
);

export const FormikCustomAutocomplete = ({name, helperText, validationKey = '', ...rest}) => {
  const customAutoComplete = React.useRef();
  const dispatch = useDispatch();
  React.useLayoutEffect(() => {
    if (customAutoComplete) {
      if (
        customAutoComplete.current !== null &&
        customAutoComplete.current.name ===
          CONSTANTS.LABELS.OTHER_THERAPEUTIC_AREA.replace(/ /g, '').toLowerCase()
      ) {
        customAutoComplete.current.focus();
      }
    }
  }, [name]);
  return (
    <Field name={name}>
      {({field: {onChange, value}, form: {errors, touched, setFieldTouched, setFieldError, setErrors}}) => (
        <CustomAutoComplete
          helperText={errors[name]}
          onChange={(value) => {
            value = Array.isArray(value) ? value : [value];
            const selectedOthers = (value || []).some((item) => item.name === 'Other');
            if (selectedOthers) {
              if (customAutoComplete) {
                customAutoComplete.current.scrollIntoView();
              }
            }
            setFieldTouched(name, true);
            onChange({target: {value, name}});
            rest.handleChange && rest.handleChange(value);
          }}
          onInputChange={(newInputValue) => {
            value = value ? (Array.isArray(value) ? value : [value]) : [];
            let duplicateValue = value.filter((option) => {
              if (Object.keys(option).length > 0) {
                return (
                  option?.name.replace(/ /g, '').toLowerCase() ===
                  newInputValue.replace(/ /g, '').toLowerCase()
                );
              }
              return false;
            });
            let res = duplicateValue.length > 0;
            dispatch(checkDuplicateValues(res));
            if (newInputValue?.length > 200) {
              if (name !== 'therapeuticAreas') {
                setFieldError(name, CONSTANTS.VALIDATION_MESSAGES.TOO_LONG);
              }
            } else {
              if (value?.length > 0) {
                setErrors({});
              } else {
                if (name === 'sponsors' || name === 'organization') {
                  setFieldError(name, CONSTANTS.VALIDATION_MESSAGES.REQUIRED);
                } else {
                  setErrors({});
                }
              }
            }
          }}
          error={
            errors[name] === CONSTANTS.VALIDATION_MESSAGES.REQUIRED
              ? false
              : errors[name] === CONSTANTS.VALIDATION_MESSAGES.TOO_LONG
              ? true
              : false
          }
          ref={customAutoComplete}
          {...rest}
        />
      )}
    </Field>
  );
};

export const FormikCustomAutocompleteConfigureGroups = ({name, helperText, validationKey = '', ...rest}) => {
  const customAutoComplete = React.useRef();
  const dispatch = useDispatch();
  React.useLayoutEffect(() => {
    if (customAutoComplete) {
      if (
        customAutoComplete.current !== null &&
        customAutoComplete.current.name ===
          CONSTANTS.LABELS.OTHER_THERAPEUTIC_AREA.replace(/ /g, '').toLowerCase()
      ) {
        customAutoComplete.current.focus();
      }
    }
  }, [name]);
  return (
    <Field name={name}>
      {({field: {onChange, value}, form: {errors, touched, setFieldTouched, setFieldError, setErrors}}) => {
        const hasConfigureGroupsError = errors.configureGroupsSites && errors.configureGroupsSites.length;
        return (
          <CustomAutoComplete
            helperText={hasConfigureGroupsError ? errors.configureGroupsSites[validationKey]?.group : null}
            onChange={(value) => {
              value = Array.isArray(value) ? value : [value];
              const selectedOthers = (value || []).some((item) => item.name === 'Other');
              if (selectedOthers) {
                if (customAutoComplete) {
                  customAutoComplete.current.scrollIntoView();
                }
              }
              setFieldTouched(name, true);
              onChange({target: {value, name}});
              rest.handleChange && rest.handleChange(value);
            }}
            onInputChange={(newInputValue) => {
              value = value ? (Array.isArray(value) ? value : [value]) : [];
              let duplicateValue = value.filter((option) => {
                if (Object.keys(option).length > 0) {
                  return (
                    option?.name.replace(/ /g, '').toLowerCase() ===
                    newInputValue.replace(/ /g, '').toLowerCase()
                  );
                }
                return false;
              });
              let res = duplicateValue.length > 0;
              dispatch(checkDuplicateValues(res));
              if (newInputValue?.length > 200) {
                if (name !== 'therapeuticAreas') {
                  setFieldError(name, CONSTANTS.VALIDATION_MESSAGES.TOO_LONG);
                }
              } else {
                if (value?.length > 0) {
                  //setErrors({})
                } else {
                  if (name.includes('group')) {
                    setFieldError(name, CONSTANTS.VALIDATION_MESSAGES.REQUIRED);
                  } else {
                    // setErrors({})
                  }
                }
              }
            }}
            ref={customAutoComplete}
            {...rest}
          />
        );
      }}
    </Field>
  );
};

export const FormikDatePicker = ({name, helperText, ...rest}) => (
  <Field name={name}>
    {({field: {value, onChange}, form: {errors, touched, setFieldTouched}}) => (
      <DatePicker
        onChange={(value) => {
          setFieldTouched(name, true);
          onChange({target: {name, value}});
        }}
        value={value}
        helperText={(touched[name] && errors[name]) || helperText}
        error={!!(touched[name] && errors[name])}
        {...rest}
        // disablePast
      />
    )}
  </Field>
);

export const FormikPasswordInputBase = ({name, helperText, classes, fullWidth, showComplexity, ...rest}) => (
  <FastField name={name}>
    {({field, form: {errors, touched}}) => (
      <div className={classes.passwordWrapper}>
        <PasswordInput
          helperText={(touched[name] && errors[name]) || helperText}
          error={!!(touched[name] && errors[name])}
          fullWidth={fullWidth}
          {...field}
          {...rest}
        />
        {showComplexity && (
          <PasswordComplexity
            className={classes.passwordComplexity}
            fullWidth={fullWidth}
            value={field.value}
          />
        )}
      </div>
    )}
  </FastField>
);

export const FormikPasswordInput = withStyles(styles)(FormikPasswordInputBase);

export const FormikPictureInputBase = ({name, classes, helperText, ...rest}) => (
  <FastField name={name}>
    {({field, form: {errors, touched}}) => (
      <>
        <FileInput
          onChange={(file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              field.onChange({
                target: {name, value: {file, thumb: reader.result}},
              });
            };
            reader.readAsDataURL(file);
          }}
          helperText={(touched[name] && errors[name]) || helperText}
          error={!!(touched[name] && errors[name])}
          {...rest}
        />
        {field.value && (
          <div className={classes.thumb}>
            <img alt="Uploaded" src={field.value.thumb} />
          </div>
        )}
      </>
    )}
  </FastField>
);

export const FormikPictureInput = withStyles(styles)(FormikPictureInputBase);
